<template>
  <div class="invite-member-modal">
    <modal
      :show="display"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
      v-on:close="displayToggle(false)"
    >
      <template v-slot:header>
        <h5 id="exampleModalLabel" class="modal-title">Add seats</h5>
      </template>
      <div class="d-flex align-items-center mb-4 users-seats-block">
        <p class="font-600 mb-0">I want to add</p>
        <div class="d-flex align-items-center justify-content-between btns mx-3">
          <base-button size="sm" type="secondary" @click="seats > minSeats && seats--">-</base-button>
          <p class="mb-0 text-center font-600 text-lg">{{ seats }}</p>
          <base-button size="sm" type="secondary" @click="seats++">+</base-button>
        </div>
        <p class="font-600 mb-0">{{ (seats > 1) ? 'seats' : 'seat' }}</p>
      </div>
      <div class="mb-3">
        The payment method on file will be charged automatically.
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-primary border-0"
          @click.prevent="displayToggle(false)"
        >
          Cancel
        </button>
        <button class="btn btn-primary" type="submit" @click="submit">
          Confirm purchase
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
import {INVITE_USER_REQUEST, PURCHASE_SEATS_REQUEST} from '@/store/storeActions';

export default {
  data() {
    const minSeats = 1;
    return {
      display: false,
      seats: minSeats,
      minSeats,
    };
  },
  methods: {
    ...mapActions([
      INVITE_USER_REQUEST,
      PURCHASE_SEATS_REQUEST,
    ]),
    displayToggle: function(isDisplay) {
      this.display = isDisplay;
      !isDisplay && (this.email = '');
    },
    submit: function() {
      this.PURCHASE_SEATS_REQUEST({seatsToPurchase: this.seats});
      this.displayToggle(false);
    },
  },
};
</script>
<style scoped>
.btns {
  width: 80px;
}
</style>
